import { Controller } from "@hotwired/stimulus";
import { Navigation, Mousewheel } from "swiper/modules";
import Swiper from "swiper";
import videojs from "video.js";
import { GeoHex } from "@uupaa/geohex";
import "video.js/dist/video-js.css";
import "swiper/css";
import "swiper/css/navigation";
import ahoy from "ahoy.js";
import { Turbo } from "@hotwired/turbo-rails";

interface VideoProps {
  postId: string;
  salonId: string;
  videoType: string;
  videoUrl: string;
}

export default class extends Controller {
  static targets = ["muteButton", "playButton", "guide"];
  declare readonly muteButtonTarget: HTMLElement;
  declare readonly guideTarget: HTMLElement;
  declare readonly playButtonTarget: HTMLElement;
  muted: boolean;
  paused: boolean;
  swiper: Swiper;
  isLogin: boolean;

  disconnect() {
    if (this.swiper) {
      this.swiper.destroy();
    }
    this.removePopStateListener();
  }

  connect(): void {
    this.muted = true;
    this.paused = false;
    this.isLogin =
      (this.element as HTMLElement).dataset.accountSignedIn == "true";

    this.swiper = this.createSwiper();
    this.addPopStateListener();

    const initialSlide = this.swiper.slides[this.swiper.activeIndex];
    if (initialSlide.dataset.url) {
      history.replaceState(
        { slideIndex: this.swiper.activeIndex },
        "",
        initialSlide.dataset.url,
      );
    }
  }

  addPopStateListener(): void {
    window.addEventListener("popstate", this.handlePopState.bind(this));
  }

  removePopStateListener(): void {
    window.removeEventListener("popstate", this.handlePopState.bind(this));
  }

  handlePopState = (event: PopStateEvent): void => {
    if (event.state && typeof event.state.slideIndex === "number") {
      this.swiper.slideTo(event.state.slideIndex, 300);
    }
  };

  createSwiper() {
    const swiperContainer = this.element.querySelector(
      ".swiper-container",
    ) as HTMLElement;

    swiperContainer.addEventListener("touchstart", (event) => {
      //console.log("touchstart")
      const currentSlide = this.swiper.slides[this.swiper.activeIndex];
      const detail = currentSlide.querySelector(".detail") as HTMLElement;
      if (!detail.contains(event.target as HTMLElement)) {
        this.permitVideos(this.swiper);
      }
    });

    return new Swiper(swiperContainer, {
      direction: "vertical",
      modules: [Navigation, Mousewheel],
      loop: false,
      mousewheel: {
        invert: false,
        thresholdDelta: 80,
      },
      navigation: {
        nextEl: ".next-button",
        prevEl: ".prev-button",
      },
      on: {
        /*
        touchStart: async (swiper, event) => {
          //this.permitVideos(swiper)
        },
        */
        afterInit: (swiper) => {
          const currentSlide = swiper.slides[swiper.activeIndex];
          ahoy.track("page_view", { post_id: currentSlide.dataset.id });
          if (window.clarity) {
            window.clarity("set", "salon_id", currentSlide.dataset.salonId);
          }
          this.currentVideoPlay(currentSlide);
        },
        slideChange: async (swiper) => {
          const currentSlide = swiper.slides[swiper.activeIndex];
          ahoy.track("swipe", { post_id: currentSlide.dataset.id });
          if (window.clarity) {
            window.clarity("set", "salon_id", currentSlide.dataset.salonId);
          }
        },

        //slidePrevTransitionStart: (swiper) => {
        // this.changeVideo(swiper)
        //},
        slideNextTransitionStart: (swiper) => {
          if (swiper.activeIndex == swiper.slides.length - 2) {
            this.updateElements(swiper);
          }
        },
        slideChangeTransitionEnd: async (swiper) => {
          const currentSlide = swiper.slides[swiper.activeIndex];
          history.pushState(
            { slideIndex: this.swiper.activeIndex },
            "",
            currentSlide.dataset.url,
          );
          ahoy.track("page_view", { post_id: currentSlide.dataset.id });

          document.dispatchEvent(
            new Event("turbo:visit", { bubbles: true, cancelable: true }),
          );

          swiper.update();

          this.changeVideo(swiper);
        },
      },
    });
  }

  async permitVideos(swiper: any) {
    this.permitVideo(swiper, swiper.activeIndex - 1);
    this.permitVideo(swiper, swiper.activeIndex + 1);
  }

  async permitVideo(swiper: Swiper, index: number) {
    const slide = swiper.slides[index];
    if (slide) {
      const player: videojs.Player = this.videoPlayer(slide);

      // console.log({ slide: slide, muted: this.muted, player: player });
      player.muted(this.muted);
      player.play();
    }
  }

  async changeVideo(swiper: Swiper) {
    const currentSlide = swiper.slides[swiper.activeIndex];
    (this.element as HTMLElement).dataset.playing = "true";
    swiper.slides.forEach((slide) => {
      const player = this.videoPlayer(slide);
      if (currentSlide == slide) {
        // console.log({ slide: slide, muted: this.muted });
        player.muted(this.muted);
        player.autoplay("any");

        this.changeMuteButtonAvailable(player);
      } else if (
        slide.classList.contains("swiper-slide-next") ||
        slide.classList.contains("swiper-slide-prev")
      ) {
        // console.log("prefetch", slide);
        player.pause();
      } else {
        // console.log("dispose", slide);
        player.pause();
        player.dispose();
      }
    });
  }

  changeMuteButtonAvailable(player: any) {
    /*
    console.log(player.audioTracks())
    console.log(player.audioTracks().length == 0)

    if (player.audioTracks().length == 0) {
      (this.muteButtonTarget as HTMLButtonElement).disabled = true
    } else {
      (this.muteButtonTarget as HTMLButtonElement).disabled = false
    }
    */
  }

  csrfToken(): string {
    const metaElement = document.querySelector('meta[name="csrf-token"]');
    if (!metaElement) {
      throw new Error("CSRF token meta tag not found");
    }

    const token = metaElement.getAttribute("content");
    if (!token) {
      throw new Error("CSRF token not found in meta tag");
    }

    return token;
  }

  updateLocation() {
    console.log("updateLocation");
    navigator.geolocation.getCurrentPosition((position) => {
      const zone = GeoHex.getZoneByLocation(
        position.coords.latitude,
        position.coords.longitude,
        10,
      );
      Turbo.visit(`/foryou/?geohex=${zone.code}`);
    });
  }

  async currentVideoPlay(currentSlide: HTMLElement): Promise<void> {
    const player: videojs.Player = this.videoPlayer(currentSlide);
    this.paused = false;
    player.muted(this.muted);
    player.autoplay("any");
    this.changeMuteButtonAvailable(player);
  }

  togglePlay(currentSlide: HTMLElement): void {
    this.paused = !this.paused;

    const frame = this.element as HTMLElement;
    if (this.paused) {
      frame.dataset.playing = "false";
      this.videoPlayer(currentSlide).pause();
    } else {
      frame.dataset.playing = "true";
      this.currentVideoPlay(currentSlide);
    }
  }

  lastVideoId(): string {
    const lastSlide: HTMLVideoElement = this.element.querySelector(
      ".swiper-slide:last-of-type",
    ) as HTMLVideoElement;

    if (!lastSlide || !lastSlide.dataset.id) {
      throw new Error("lastVideoId not found in slide");
    }
    return lastSlide.dataset.id;
  }

  fetchUrl() {
    return (
      (this.element as HTMLElement).dataset.fetchPath +
      "?last_id=" +
      this.lastVideoId()
    );
  }

  updateElements(swiper: Swiper) {
    const url = this.fetchUrl();
    console.log(`fetch_url: ${url}`);
    fetch(url, { headers: { Accept: "text/vnd.turbo-stream.html" } })
      .then((response) => {
        return response.text();
      })
      .then((message) => {
        Turbo.renderStreamMessage(message);
      })
      .then(() => {
        if (swiper.slides.length > 5 && swiper.activeIndex > 3) {
          Array.from(swiper.slides)
            .slice(0, swiper.activeIndex - 1)
            .forEach((slide: HTMLElement) => {
              this.videoPlayer(slide).dispose();
              swiper.wrapperEl.removeChild(slide);
            });
          swiper.slideTo(1, 0, false);
        }
      })
      .then(() => {
        swiper.update();
      });
  }

  createVideoElement({
    postId,
    salonId,
    videoType,
    videoUrl,
  }: VideoProps): HTMLVideoElement {
    const video = document.createElement("video");
    video.dataset.id = postId;
    video.dataset.salonId = salonId;

    const sourceElement = document.createElement("source") as HTMLSourceElement;
    sourceElement.type = videoType;
    sourceElement.src = videoUrl;
    video.appendChild(sourceElement);

    return video;
  }

  getSlideProps(slideElement: HTMLElement): VideoProps {
    const postId = slideElement.dataset.id;
    const salonId = slideElement.dataset.salonId;
    const videoType = slideElement.dataset.videoType;
    const videoUrl = slideElement.dataset.videoUrl;

    if (!postId || !salonId || !videoType || !videoUrl) {
      throw new Error(
        "Required data attributes are missing from slide element",
      );
    }

    return { postId, salonId, videoType, videoUrl };
  }

  videoPlayer(slideElement: HTMLElement): videojs.Player {
    const props = this.getSlideProps(slideElement);
    const videoJSWrapper =
      slideElement.querySelector(".video-js") ||
      slideElement.querySelector("video");

    if (!videoJSWrapper) {
      const videoElement = this.createVideoElement(props);
      const detailElement = slideElement.querySelector(".detail");
      if (detailElement) {
        slideElement.insertBefore(videoElement, detailElement);
      }
      return this.videoPlayer(slideElement);
    }

    const player: videojs.Player = videojs(videoJSWrapper, {
      muted: this.muted,
      loop: true,
      playsinline: true,
    });

    const playerElement = player.el() as HTMLElement;
    const postId = playerElement.dataset.id;
    const salonId = playerElement.dataset.salonId;

    const state = {
      loopCount: 1,
      statues: {
        0: false,
        20: false,
        40: false,
        60: false,
        80: false,
        100: false,
      },
    };

    const nextParcentage = (): number => {
      // オブジェクト内のキー（数値）を配列に取得
      const keys = Object.keys(state.statues);

      // 配列を数値の昇順にソート
      keys.sort((a, b) => parseInt(a) - parseInt(b));

      // ソートされたキーの中から最初のfalseの値を取得
      for (const key of keys) {
        if (!state.statues[key]) {
          return parseInt(key) as number;
        }
      }

      throw new Error("nextParcentage failed");
    };

    player.on("error", () => {
      console.error("Video playback error occurred:", player.error());
      // player.loadingSpinner.hide();
    });
    player.on("play", () => {
      this.paused = false;
    });
    player.on("pause", () => {
      this.paused = true;
    });
    player.on("timeupdate", () => {
      const currentTime = player.currentTime();
      const duration = player.duration();
      const progressPercentage = (currentTime / duration) * 100;
      const nextProgress = nextParcentage();

      if (nextProgress == 100 && progressPercentage < 20) {
        ahoy.track("video_complete", { post_id: postId, salon_id: salonId });
        ahoy.track("video_progress", {
          loop: state.loopCount,
          post_id: postId,
          salon_id: salonId,
          progressPercentage: progressPercentage,
          progress: nextProgress,
          duration: duration,
        });
        state.loopCount += 1;
        state.statues = {
          0: false,
          20: false,
          40: false,
          60: false,
          80: false,
          100: false,
        };
      } else if (nextProgress <= progressPercentage) {
        state.statues[nextProgress] = true;
        ahoy.track("video_progress", {
          loop: state.loopCount,
          post_id: postId,
          salon_id: salonId,
          progressPercentage: progressPercentage,
          progress: nextProgress,
          duration: duration,
        });
      }
    });

    return player;
  }

  // --- actions

  actionToggleMenu(): void {
    this.element.classList.toggle("open");
  }

  actionPlay(): void {
    const currentSlide = this.swiper.slides[this.swiper.activeIndex];
    this.togglePlay(currentSlide);
  }

  actionToggleMute(): void {
    this.muted = !this.muted;

    if (this.muted) {
      this.muteButtonTarget.classList.add("muted");
    } else {
      (this.element as HTMLElement).dataset.playing = "true";
      this.muteButtonTarget.classList.remove("muted");
    }
    const currentSlide = this.swiper.slides[this.swiper.activeIndex];
    this.currentVideoPlay(currentSlide);
  }

  hideGuide() {
    this.guideTarget.classList.add("hidden");
    localStorage.setItem("guide", "hidden");
    ahoy.track("hide_guide");
  }
}
